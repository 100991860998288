<template>
  <div class="container-fluid p-0 home-bg">
    <dsptHeader></dsptHeader>
    <div class="row h-100">
      <div class="col-lg-6  text-center  my-auto">Member Admin </br> Column 1</div>
      <div class="col-lg-6  text-center  my-auto">Member Admin </br> Column 2</div>
      
      </div>
  </div>
</template>

<script>
// Leaflet
import dsptHeader from "@/components/header";

// Vuex
import { mapGetters } from "vuex";

// Components and services

export default {
  name: "MemberAdmin",
  components: {
    dsptHeader,
  },
  computed: {
    ...mapGetters({
      status: "ui/status",
      current_interface: "ui/current_interface",
    }),
  },
  data() {
    return {

    };
  },
  created() {
    /*
    let globalThis = this;
    this.socket = new WebSocket("ws://localhost:8000/ws/updates/");
    this.socket.addEventListener("open", () => {
      console.log("Conectado");
    });
    this.socket.onmessage = function (event) {
      const data = JSON.parse(event.data);
      //globalThis.checkResponse(data)
      console.log(data);
    };*/
  },

  mounted() {
 
  },
  watch: {

  },

  methods: {
   
  },
};
</script>
<style scoped>
</style>