import axios from 'axios'

const getlocalToken = (key) => {
    if (key == 'interest' || key == 'favs'){ //no hay manera de saber si antes era un objeto... no me mola hacerlo así
        return JSON.parse(localStorage.getItem(key))
    } else {
        return localStorage.getItem(key)
    }
}

const setlocalToken = (key, value) => {
    localStorage.setItem(key, value)
    axios.defaults.headers.common['Authorization'] = "Bearer  " + value
}

const setlocal = (key, value) => {
    if (typeof(value) == 'object'){
        localStorage.setItem(key, JSON.stringify(value)) 
    } else {
        localStorage.setItem(key, value)
    }
}

const deletelocalToken = (key) => {
    localStorage.removeItem(key)
    delete axios.defaults.headers.common['Authorization']
};

const deleteAll = () => {
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization']
};


export {
    getlocalToken,
    setlocalToken,
    deletelocalToken,
    setlocal,
    deleteAll
}