<template>
    <div class="job_column">
          <div class="job_card" v-for="(job, index) in jobs" v-bind:key="index">
            <div class="row content_job_card">
                <div class="col-lg-8 text-left my-auto ">
                    <h4>{{job.job_title}}</h4>
                    <h5>{{job.organisation}}</h5>
                    <div class="row">

                      <div class="col-md-auto badge badge-secondary small p-1 ml-1 mb-1" 
                          v-for="sector in job.sector" v-bind:key="sector" >{{sectors[sector]}}
                      </div>

                      <div class="col-md-auto badge badge-primary small p-1 ml-1 mb-1" 
                        v-for="type_of_organisation in job.type_of_organisation" v-bind:key="type_of_organisation">{{type_of_organisations[type_of_organisation]}}
                      </div>

                      <div class="col-md-auto badge badge-success small p-1 ml-1 mb-1" 
                        v-for="career_level in job.career_level" v-bind:key="career_level">{{career_levels[career_level]}}
                      </div> 

                    </div>
                </div>
                <div class="col-lg-4 text-right my-auto ">
                    <h5 v-if="job.city">{{job.city}}, 
                      <div v-if="job.various_locations">Various Location</div>
                      <div v-else>{{job.countryName}}</div>
                      </h5>
                    <h5 v-else>
                      <div v-if="job.various_locations">Various Location</div>
                      <div v-else>{{job.countryName}}</div>
                    </h5>


                    <h6>Deadline: 
                      <div class="job_deadline_red" v-if="job.closed">{{job.deadlineConverted}} - Closed</div>
                      <div v-else>{{job.deadlineConverted}}</div>
                      </h6>
                                     
                    <div v-if="checkFav(job.id)"> <img @click="markDeleteAsFav(job.id, user_id)" class ='icons-login' :src="require('@/assets/icons/heart.png')"/></div> 
                    <div v-else> <img @click="markDeleteAsFav(job.id, user_id)" class ='icons-login' :src="require('@/assets/icons/heart_empty.png')"/> </div>
                    
                   
                    
              </div>
            </div>
          </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {sectors, type_of_organisations, career_levels} from "../../services/consts"


export default {
  name: "Job",

  components: {
    PulseLoader
  },

  props: {},

  data() {
    return {
      sectors,
      type_of_organisations,
      career_levels,
    };
  },

  watch: { },

  computed: { 
    ...mapGetters({jobs: 'jobs/jobs', user_id: 'user/user_id', favs: 'user/getFavs'}),
  },

  mounted() {},

  methods: {

    markDeleteAsFav: function (job_id, user_id) {
      this.$store.dispatch("user/to_add_remove_fav", {
        user: user_id,
        job: job_id
      })
        this.checkFav(job_id);
    },

    checkFav: function (job_id) {
      if (this.favs.find((element) => element == job_id) == job_id)  return true;
    }
    
  },
};
</script> 

<style lang = "scss" scoped>

</style>