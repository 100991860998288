<template>
    <div>
        <div class="col-12 text-center">
            <h5>
                <label for="Login info">New job seeker registration</label>
            </h5>
        </div>
        <div class="form-group row pb-2">
            <div class="col pl-0 text-center row">
                <div class="col-9">Register to access the largest job database in marine sciences. Already have an account?</div>
                    <!-- <a href="#" class="col-3 badge ">Register!</a> -->
                <router-link  to="/login" exact> 
                    <a @click="to_login" class="modal-call pointer">Sign in here</a>  
                </router-link>
            </div>
        </div>

        <form @submit.prevent="setUser">
            <!-- <h4 v-if="user_id">Edit Profile</h4>
            <h4 v-else>New User</h4> -->
            <!-- <div class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Username</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="form.username" placeholder="Username">
                    <label v-bind:class="{ ocultar: !$v.form.username.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div> -->

            <div class="form-group row mb-1">
                <label  class="col-sm-3 col-form-label ">Email</label>
                <div class="col-sm-9 row ml-1">
                    <input type="email" class="form-control col" v-model="form.email" placeholder="Email" required>
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.email.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9 row ml-1">
                    <input type="text" class="form-control col" v-model="form.first_name" placeholder="Name">
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.first_name.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Surname</label>
                <div class="col-sm-9 row ml-1">
                    <input type="text" class="form-control col" v-model="form.last_name" placeholder="Surname">
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.last_name.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="form-group row mb-1">
                <label  class="col-sm-3 col-form-label">City</label>
                <div class="col-sm-9 row ml-1">
                    <input type="city" class="form-control col" v-model="form.city" placeholder="City">
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.city.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="form-group row mb-1">
                <label  class="col-sm-3 col-form-label">Country</label>
                <div class="col-sm-9 row ml-1">
                    <input type="country" class="form-control col" v-model="form.country" placeholder="Country">
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.country.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="form-group row mb-1">
                <label for="interest" class="col-sm-3 col-form-label">Interest</label>
                <div class="col-sm-9 row ml-1 ">
                    <select id="interest"  class="form-control selectpicker col" v-model="form.interest" placeholder="Interest" multiple>
                        <option>Climate change</option>
                        <option>Environmental education</option>
                        <option>Fisheries & aquaculture</option>
                        <option>Management & Consultancy</option>
                        <option>Marine policy</option>
                        <option>Marine renewable energy</option>
                        <option>Naval & marine engineering</option>
                        <option>Oceanography</option>
                        <option>Water & hydrology</option>
                    </select>
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.interest.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="form-group row mb-1">
                <label  for="experience" class="col-sm-3 col-form-label">Are you</label>
                <div class="col-sm-9 row ml-1">
                    <select id="experience" type="experience" class="form-control col" v-model="form.experience" placeholder="Experience">
                        <option>Intern & recent graduate</option>
                        <option>Junior (1-5 years of experience)</option>
                        <option>Senior (<5 years of experience)</option>
                    </select>
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.form.experience.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="form-group row mb-3">
                <label  class="col-sm-3 col-form-label">Linkedin</label>
                <div class="col-sm-9 row ml-1">
                    <input type="linkedin" class="form-control col-sm-10" v-model="form.linkedin" placeholder="Linkedin">
                    <!-- <label v-bind:class="{ ocultar: !$v.form.linkedin.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field is required.
                    </label> -->
                </div>
            </div>

            <div v-if="!user_id" class="form-group row mb-3">
                <label class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9 row ml-1">
                    <input
                    class="form-control col-sm-10"
                    v-model="password"
                    :v="$v.password"
                    type="password"
                    placeholder="Password"
                    />
                    <div class="col-sm-2 required">*</div>
                    <div v-bind:class="{ ocultar: !$v.password.$error }" >
                        <label v-if = "!$v.password.minLength"  class=" col-form-label invalid p-0">It must contain at least 8 characters.</label>
                        <label v-bind:class="{ ocultar: $v.password.notOnlyNumeric }" class=" col-form-label invalid p-0 ml-2">Don't just use numbers.</label>
                    </div>
                </div>
            </div>
            
            <div v-if="!user_id"  class="form-group row  mb-3">
                <label class="col-sm-3 col-form-label">Repeat password</label>
                <div class="col-md-9 row ml-1">
                    <input
                    class="form-control form-password col-sm-10"
                    v-model="password2"
                    type="password"
                    :v="$v.password2"
                    placeholder="Repeat password"
                    />
                    <div class="col-sm-2 required">*</div>
                    <label v-bind:class="{ ocultar: !$v.password2.$error }" class=" col-form-label invalid p-0">
                        Password fields didn't match.
                    </label>
                </div>
            </div>

            <div class="form-group row">
                <!-- <div class="col-sm-2">Checkbox</div> -->
                <div class="col-sm-12 ">
                    <div class="form-check ">
                        <input class="form-check-input" type="checkbox" v-model="form.privacy" id="gridCheck1" >
                        <label class="form-check-label row ml-1 " for="gridCheck1">
                           <a class="col-sm-10 " href="#"> I accept Privacy Policy and Terms and Conditions </a>
                           <div class="col-sm-2 required">*</div>
                        </label>
                        
                        <label v-bind:class="{ ocultar: !$v.form.privacy.$error }" class="col-form-label invalid col-sm-12 p-0">
                            You must agree before submitting.
                        </label>
                    </div>
                </div>
            </div>

           
            <div  v-if="user_id && !personalProfile" class="form-group row">
                <div class="col-sm-3">Active</div>
                <div class="col-sm-9">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.is_active" >
                        <label class="form-check-label" >
                            Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="(is_admin == true || is_admin == 'true') && !personalProfile" class="form-group row ">
                <div class="col-sm-3">Superuser status</div>
                <div class="col-sm-9">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox"  v-model="form.is_superuser" >
                        <label class="form-check-label">
                            Designates that this user has all permissions without explicitly assigning them.
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-4 text-left">
                    <button type="button" v-if="user_id && (is_admin == true || is_admin == 'true') && !personalProfile" @click="deleteUser()" class="btn btn-danger ">Delete</button>
                </div>
                <div class="col-sm-8 text-right">
                     <!-- <a v-if="!personalProfile" @click="$parent.showUserList = true" class="link-1" > User List </a> -->
                    <button type="submit" class="btn btn-secondary ml-3">Register</button>
                </div>
            </div>
        </form>
    
        <form v-if="personalProfile" @submit.prevent="changePassword">
            <h4>Change Password</h4>
            <div  class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Old password</label>
                <div class="col-sm-9">
                    <input
                    class="form-control"
                    v-model="old_password"
                    :v="$v.old_password"
                    type="password"
                    placeholder="Old password"
                    />
                </div>
                <label class="ocultar col-form-label invalid p-0">It </label>
            </div>
            <div  class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                    <input
                    class="form-control"
                    v-model="password"
                    :v="$v.password"
                    type="password"
                    placeholder="Password"
                    />
                    <div v-bind:class="{ ocultar: !$v.password.$error }" >
                        <label v-if = "!$v.password.minLength"  class=" col-form-label invalid p-0">It must contain at least 8 characters.</label>
                        <label v-bind:class="{ ocultar: $v.password.notOnlyNumeric }" class=" col-form-label invalid p-0 ml-2">Don't just use numbers.</label>
                    </div>
                </div>
            </div>
            <div class="form-group row  mb-1">
                <label class="col-sm-3 col-form-label">Repeat password</label>
                <div class="col-md-9">
                    <input
                    class="form-control"
                    v-model="password2"
                    type="password"
                    :v="$v.password2"
                    placeholder="Repeat password"
                    />
                    <label v-bind:class="{ ocultar: !$v.password2.$error || password2 == '' }" class=" col-form-label invalid p-0">
                        Password fields didn't match.
                    </label>
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-secondary ml-3">Update</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import { postData, putData, deleteData } from "@/services/api";
import { mapGetters } from 'vuex'
import { toaster_error, toaster_success } from '../../services/helper';

import {
  required,
  minLength,
  sameAs,
  not,
  numeric
} from "vuelidate/lib/validators";

export default {
  name: 'userEdit',
  props: {
    user: Object,
    personalProfile: Boolean 
  },
  data() {
    return {
        password: '',
        password2: '',
        old_password: '',
        user_id: '',
         form: {
            email: '',
            username: '',
            first_name: '',
            last_name: '',
            is_active: '',
            city:'',
            country:'',
            interest: [""],
            experience:'',
            linkedin:'',
            is_superuser: false,
         }
    };
  },
  validations: {
    form: {
      email: { required },
    //   username: "",
      first_name: { required },
      last_name: { required },
      city: { required },
      country: { required },
      interest: { required },
      experience: { required },
    //   linkedin: { required },
      privacy: { required}
    },
    old_password: {required},
    password: {required,notOnlyNumeric:not(numeric),minLength: minLength(8)},
    password2: {required, sameAsPassword: sameAs("password") }
  },
  computed: { 
    ...mapGetters("user",{
      is_admin: 'is_admin',
      id: 'user_id',
    //   username: 'username',
      first_name: 'first_name',
      last_name: 'last_name',
      email: 'email',
      city: 'city',
      country: 'country',
      interest: 'interest',
      experience: 'experience',
      linkedin: 'linkedin',
    //   privacy: 'privacy'

    }) 


  },
  created () {
    if(this.personalProfile == true){
        this.user_id = this.id 
        this.form['id'] = this.user_id
        this.form['username'] = this.email
        this.form['first_name'] = this.first_name
        this.form['last_name'] = this.last_name
        this.form['email'] = this.email
        this.form['city'] = this.city
        this.form['country'] = this.country
        this.form['interest'] = this.interest
        this.form['experience'] = this.experience
        this.form['linkedin'] = this.linkedin
        this.form['privacy'] = this.privacy
        
    }else if (this.user.id){
        this.user_id = this.user.id 
        this.form = this.user
    }
  },
  
  methods: {
      setUser(){
        if (!this.user_id) {
            //new user
            this.$v.password.$touch();
            this.$v.password2.$touch();
            this.$v.form.$touch();
            if (this.$v.password.$error || this.$v.password2.$error || this.$v.form.$pending || this.$v.form.$error) return;

            this.form['password'] = this.password
            this.form['password2'] = this.password2

            this.form['username'] = this.form['email']
            
            this.$store.dispatch("ui/change_status", 'loading', { root: true });
            postData("auth-jwt/register/", this.form)
            .then(res => { 
                if(res.status < 300) {        
                    toaster_success("Register succesfull")
                    this.$store.dispatch("ui/change_status", 'ok', { root: true });
					this.$store.dispatch("user/to_login");
                } else if (res.status != undefined) {
                    if (res.status == 400){
                        toaster_error("Error registing user: Email already used" )
                    } else {
                        toaster_error("Error registing user: " + res.statusText)
                    }
                    this.$store.dispatch("ui/change_status", 'error', { root: true });
                } else {
                    toaster_error("Error registing user: Network Error")
                    this.$store.dispatch("ui/change_status", 'error', { root: true });
                }
            })
            .catch(err => {
                toaster_error("Error registing user: Unknown Error")
                console.log('error en register ')
                this.$store.dispatch("ui/change_status", 'error', { root: true });
            })

        }else{
            //Update
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) return; 
            if(this.personalProfile == true){
                delete this.form.is_superuser
                delete this.form.is_active
            }

            this.form['username'] = this.form['email'] //Check if it is ok.

            this.$store.dispatch("ui/change_status", 'loading', { root: true });
            putData("auth-jwt/update-profile/",  this.user_id, this.form)
            .then(res=> {
                if(res.status < 300) {    
                    toaster_success("User update succesfull")    
                    this.$store.dispatch("ui/change_status", 'ok', { root: true });
					if(this.personalProfile == false){
                        this.form = {}
                        this.$parent.showUserList = true
                        this.$parent.getUser()
                    }
                } else if (res.status != undefined) {
                    toaster_error("Error fetching Jobs: " + res.statusText)
                    this.$store.dispatch("ui/change_status", 'error', { root: true });
                } else {
                    toaster_error("Error fetching Jobs: Network Error")
                    this.$store.dispatch("ui/change_status", 'error', { root: true });
                }
            })
            .catch(err => {
                toaster_error("Error fetching Jobs: Unknown Error")
                this.$store.dispatch("ui/change_status", 'error', { root: true });
            })
        }
      },


      deleteUser(){
        var destroy = confirm("Are you sure you want to delete the user '"+this.form['first_name']+' '+this.form['last_name']+"'?")
        if(destroy){
            deleteData("auth-jwt/delete-profile/", this.user_id)
                .then(res => {
                    if(res.status < 400){
                        //this.$noty.success("Successful update");
                        this.$parent.showUserList = true
                        this.$parent.getUser()
                    }else{
                        toaster_error("Error deleting user: " + res.statusText);
                    } 
                })
                .catch(error => {
                    toaster_error("Error deleting user: Unknown Error");
                });
        }

      },

      changePassword(){
        this.$v.password.$touch();
        this.$v.password2.$touch();
        this.$v.old_password.$touch();
        if (this.$v.password.$error || this.$v.password2.$error || this.$v.old_password.$error ) return;

        var data={
            'password': this.password,
            'password2': this.password2,
            'old_password': this.old_password,
        }

        putData("auth-jwt/change-password/",  this.user_id, data)
            .then(res => {
                if(res.status < 400){
                    //this.$noty.success("Successful update");
                    this.password = ''
                    this.password2 = ''
                    this.old_password = ''
                }else{
                    toaster_error("Error changing pasword: " + res.statusText);
                } 
                })
            .catch(error => {
                toaster_error("Error changing pasword: Unknown Error");
            });
      },

      to_login: function () {
        this.$store.dispatch("user/to_login");
    }


  }
};
</script>

<style lang = "scss" scoped>
.required  {
    /* content: "*"; */
    color: red;
}

</style>
