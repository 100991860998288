import { postData } from "@/services/api";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Vuelidate from "vuelidate";

Vue.use(VueToast, Vuelidate);

const state = () => ({
  // variables de los INPUTS
  formInputs: {
    name: "",
    longitude: 0,
    latitude: 0,
    depth: 0,
    equipo_list: [
      {
        id: "",
        name: "",
        sensivity: "",
        fc: "",
        gp: "",
        pref: "",
      },
    ],
    equipo_manual: {
      name: "",
      sensivity: "",
      fc: "",
      gp: "",
      pref: "agua",
    },
    equipo_selected: 1,
    sound_crop_start: 0,
    sound_crop_end: 0,
    proces_dft: false,
    proces_spl: false,
    proces_d11: false,
    proces_spl_data: {
      freq_start: "",
      freq_end: "",
      paso: 1,
      filtro: 1,
      orden: "",
    },
    proces_d11_data: {
      filtro: 1,
      orden: "",
    },
  },
  // Variables de ARCHIVOS SUBIDOS
  signalProps: {
    signal_nums: "",
    sampling: "",
    resolution: "",
    duration: "",
    size: "",
  },
  // variables GENERALES
  stateVars: {
    job_id: "",
    num_files_uploaded: "",
    fileNames: [],
  },
  filtro_list: [
    {
      id: 1,
      nombre: 'Butterworth',
      valor: 'butter',
      tipo: 'iir'
    },
    {
      id: 2,
      nombre: 'Chebyshev (Tipo 1)',
      valor: 'cheby',
      tipo: 'iir'
    },
    {
      id: 3,
      nombre: 'Chebyshev (Tipo 2)',
      valor: 'cheby2',
      tipo: 'iir'
    }
  ],
  paso_list: [
    {
      id: 1,
      nombre: 'Octavas',
      valor: 'octavas'
    },
    {
      id: 2,
      nombre: 'Tercios',
      valor: 'tercios'
    },
    {
      id: 3,
      nombre: 'Decidécadas',
      valor: 'decidecadas'
    },
    {
      id: 4,
      nombre: 'Doceavos',
      valor: 'doceavos'
    }
  ],
  response1: []
});

const mutations = {
  set_name(state, data) {
    state.formInputs.name = data;
  },
  set_longitude(state, data) {
    state.formInputs.longitude = data;
  },
  set_latitude(state, data) {
    state.formInputs.latitude = data;
  },
  set_depth(state, data) {
    state.formInputs.depth = data;
  },
  set_equip_med(state, data) {
    state.formInputs.equip_med.name = data.name;
    state.formInputs.equip_med.freq = data.freq;
  },
  set_sound_crop_start(state, data) {
    state.formInputs.sound_crop_start = data;
  },
  set_sound_crop_end(state, data) {
    state.formInputs.sound_crop_end = data;
  },
  set_proces_dft(state, data) {
    state.formInputs.proces_dft = data;
  },
  set_proces_spl(state, data) {
    state.formInputs.proces_spl = data;
  },
  // state.formInputs.proces_spl.freq_end = data.freq_end;
  // state.formInputs.proces_spl.paso = data.paso;
  // state.formInputs.proces_spl.filtro = data.filtro;
  // state.formInputs.proces_spl.orden = data.orden;
  set_proces_spl_data_freq_start(state, data) {
    state.formInputs.proces_spl_data.freq_start = data;
  },
  set_proces_spl_data_freq_end(state, data) {
    state.formInputs.proces_spl_data.freq_end = data;
  },
  set_proces_spl_data_paso(state, data) {
    state.formInputs.proces_spl_data.paso = data;
  },
  set_proces_spl_data_filtro(state, data) {
    state.formInputs.proces_spl_data.filtro = data;
  },
  set_proces_spl_data_orden(state, data) {
    state.formInputs.proces_spl_data.orden = data;
  },
  set_proces_d11(state, data) {
    state.formInputs.proces_d11 = data;
  },
  set_proces_d11_data_filtro(state, data) {
    state.formInputs.proces_d11_data.filtro = data;
  },
  set_proces_d11_data_orden(state, data) {
    state.formInputs.proces_d11_data.orden = data;
  },
  set_equipo_list(state, data){
    state.formInputs.equipo_list = data
  },
  set_equipo_selected(state, data){
    state.formInputs.equipo_selected = data
  },
  set_equipo_manual_name(state, data) {
    state.formInputs.equipo_manual.name = data
  },
  set_equipo_manual_sensivity(state, data) {
    state.formInputs.equipo_manual.sensivity = data
  },
  set_equipo_manual_fc(state, data) {
    state.formInputs.equipo_manual.fc = data
  },
  set_equipo_manual_gp(state, data) {
    state.formInputs.equipo_manual.gp = data
  },
  set_equipo_manual_pref(state, data) {
    state.formInputs.equipo_manual.pref = data
  },
  // Variables de ARCHIVOS SUBIDOS
  set_signal_props(state, data) {
    state.signalProps = data
  },
  set_signal_nums(state, data) {
    state.signalProps.signal_nums = data;
  },
  // Variables GENERALES
  set_job_id(state, data) {
    state.stateVars.job_id = data;
  },
  set_num_files_uploaded(state, data) {
    state.stateVars.num_files_uploaded = data
  },
  set_fileNames(state, data) {
    state.stateVars.fileNames = data;
  },
  set_response1(state, data) {
    state.response1 = data
  }
};

const actions = {
  change_name({ commit }, data) {
    commit("set_name", data);
  },
  change_longitude({ commit }, data) {
    commit("set_longitude", data);
  },
  change_latitude({ commit }, data) {
    commit("set_latitude", data);
  },
  change_depth({ commit }, data) {
    commit("set_depth", data);
  },
  change_proces_dft({ commit }, data) {
    commit("set_proces_dft", data);
  },
  change_proces_spl({ commit }, data) {
    commit("set_proces_spl", data);
  },
  change_proces_spl_data_freq_start({ commit }, data) {
    commit("set_proces_spl_data_freq_start", data);
  },
  change_proces_spl_data_freq_end({ commit }, data) {
    commit("set_proces_spl_data_freq_end", data);
  },
  change_proces_spl_data_paso({ commit }, data) {
    commit("set_proces_spl_data_paso", data);
  },
  change_proces_spl_data_filtro({ commit }, data) {
    commit("set_proces_spl_data_filtro", data);
  },
  change_proces_spl_data_orden({ commit }, data) {
    commit("set_proces_spl_data_orden", data);
  },

  // change_proces_spl_data({ commit }, data) {
  //   commit("set_proces_spl_data", data);
  // },
  change_proces_d11({ commit }, data) {
    commit("set_proces_d11", data);
  },
  change_proces_d11_data_filtro({ commit }, data) {
    commit("set_proces_d11_data_filtro", data);
  },
  change_proces_d11_data_orden({ commit }, data) {
    commit("set_proces_d11_data_orden", data);
  },
  change_equipo_list({ commit }, data) {
    commit("set_equipo_list", data);
  },
  change_equipo_selected({ commit }, data) {
    commit("set_equipo_selected", data);
  },
  change_sound_crop_start({ commit }, data) {
    commit("set_sound_crop_start", data);
  },
  change_sound_crop_end({ commit }, data) {
    commit("set_sound_crop_end", data);
  },
  change_equipo_manual_name({ commit }, data) {
    commit("set_equipo_manual_name", data)
  },
  change_equipo_manual_sensivity({ commit }, data) {
    commit("set_equipo_manual_sensivity", data)
  },
  change_equipo_manual_fc({ commit }, data) {
    commit("set_equipo_manual_fc", data)
  },
  change_equipo_manual_gp({ commit }, data) {
    commit("set_equipo_manual_gp", data)
  },
  change_equipo_manual_pref({ commit }, data) {
    commit("set_equipo_manual_pref", data)
  },
  // Variables de archivos subidos
  change_signal_props({ commit }, data) {
    commit("set_signal_props", data)
  },
  change_signal_nums({ commit }, data) {
    commit("set_signal_nums", data)
  },
  // Variables generales
  change_job_id({ commit }, data) {
    commit("set_job_id", data)
  },
  change_num_files_uploaded({ commit }, data) {
    commit("set_num_files_uploaded", data)
  },
  change_fileNames({ commit }, data) {
    commit("set_fileNames", data)
  },

  // ACCIÓN PARA COMENZAR EL PROCESADO
  submit_processing({ commit, dispatch, getters }) {
    console.log("Entra a la acción")
    // Set 'loading' status
    dispatch("ui/change_current_interface", null, { root: true });
    dispatch("ui/change_status", 'loading', { root: true });

    let formData = new FormData()
    for (const key in getters['formInput']) {
      if (key == "equipo_list" || key == "equipo_selected" || key == "equipo_manual" || key == "proces_spl_data" || key == "proces_d11_data"){
        console.log('No enter')
      } 
      else {
        formData.append(key, getters['formInput'][key]);
        console.log(key + ': ' + getters['formInput'][key])
      }   
    }

    // EQUIPO
    let sensivity = ''
    let fc = ''
    let gp = ''
    let pref = ''
    if (getters['formInput'].equipo_selected == 0) {
      sensivity = getters['formInput'].equipo_manual.sensivity
      fc = getters['formInput'].equipo_manual.fc
      gp = getters['formInput'].equipo_manual.gp
      pref = getters['formInput'].equipo_manual.pref
    } else {
      sensivity = getters['formInput'].equipo_list[parseFloat(getters['formInput'].equipo_selected) - 1].sensivity
      fc = getters['formInput'].equipo_list[parseFloat(getters['formInput'].equipo_selected) - 1].fc
      gp = getters['formInput'].equipo_list[parseFloat(getters['formInput'].equipo_selected) - 1].gp
      pref = getters['formInput'].equipo_list[parseFloat(getters['formInput'].equipo_selected) - 1].pref
      if (pref == 'Agua') pref = 'agua'
      if (pref == 'Aire') pref = 'aire'
    }
    formData.append('sensivity', sensivity)
    console.log('sensivity: ', sensivity)
    formData.append('fc', fc)
    console.log('fc: ', fc)
    formData.append('gp', gp)
    console.log('gp: ', gp)
    formData.append('pref', pref)
    console.log('pref: ', pref)

    // SPL
    formData.append('spl_f_start', getters['proces_spl_data_freq_start'])
    console.log('spl_f_start: ', getters['proces_spl_data_freq_start'])
    formData.append('spl_f_end', getters['proces_spl_data_freq_end'])
    console.log('spl_f_end: ', getters['proces_spl_data_freq_end'])
    formData.append('spl_paso', getters['paso_list'][parseFloat(getters['proces_spl_data_paso']) -1].valor)
    console.log('spl_paso: ', getters['paso_list'][parseFloat(getters['proces_spl_data_paso']) -1].valor)
    formData.append('spl_filtro', getters['filtro_list'][parseFloat(getters['proces_spl_data_filtro']) -1].valor)
    console.log('spl_filtro: ', getters['filtro_list'][parseFloat(getters['proces_spl_data_filtro']) -1].valor)
    formData.append('spl_filtro_tipo', getters['filtro_list'][parseFloat(getters['proces_spl_data_filtro']) -1].tipo)
    console.log('spl_filtro_tipo: ', getters['filtro_list'][parseFloat(getters['proces_spl_data_filtro']) -1].tipo)
    formData.append('spl_orden', getters['proces_spl_data_orden'])
    console.log('spl_orden: ', getters['proces_spl_data_orden'])

    // D11
    formData.append('d11_filtro', getters['filtro_list'][parseFloat(getters['proces_d11_data_filtro']) -1].valor)
    console.log('d11_filtro: ', getters['filtro_list'][parseFloat(getters['proces_d11_data_filtro']) -1].valor)
    formData.append('d11_filtro_tipo', getters['filtro_list'][parseFloat(getters['proces_d11_data_filtro']) -1].tipo)
    console.log('d11_filtro_tipo: ', getters['filtro_list'][parseFloat(getters['proces_d11_data_filtro']) -1].tipo)
    formData.append('d11_orden', getters['proces_d11_data_orden'])
    console.log('d11_orden: ', getters['proces_d11_data_orden'])

    // JOB ID
    formData.append('job_id', getters['job_id'])
    console.log('job_id: ', getters['job_id'])

    // POST FORM DATA
    postData('processing/processdata/', formData)
    .then((res) => {
      if (res.status == "200") {
        //commit("set_transect_1", res.data.transect_1);
        //commit("set_transect_2", res.data.transect_2);
        console.log("YEP!")
        commit("set_response1", res.data.Y)

        // Remove 'loading' status and display results
        dispatch("ui/change_current_interface", 8, { root: true });
        dispatch("ui/change_status", 'ok', { root: true });
      } else {
        dispatch("ui/change_current_interface", 7, { root: true });
        dispatch("ui/change_status", 'ok', { root: true });
        Vue.$toast.open({
          message: "Something went wrong. Process stopped.",
          type: "error",
          position: "top",
          pauseOnHover: true,
          duration: 4000,
        });
      }
    })
    .catch((err) => console.log(err));
  }
};

const getters = {
  // Objeto con todos los inputs
  formInput: (state) => state.formInputs,

  // Objetos individuales
  name: (state) => state.formInputs.name,
  longitude: (state) => state.formInputs.longitude,
  latitude: (state) => state.formInputs.latitude,
  depth: (state) => state.formInputs.depth,
  equip_med: (state) => state.formInputs.equip_med,
  sound_crop_start: (state) => state.formInputs.sound_crop_start,
  sound_crop_end: (state) => state.formInputs.sound_crop_end,
  proces_dft: (state) => state.formInputs.proces_dft,
  proces_spl: (state) => state.formInputs.proces_spl,
  proces_spl_data: (state) => state.formInputs.proces_spl_data,
  proces_spl_data_freq_start: (state) =>
    state.formInputs.proces_spl_data.freq_start,
  proces_spl_data_freq_end: (state) =>
    state.formInputs.proces_spl_data.freq_end,
  proces_spl_data_paso: (state) => state.formInputs.proces_spl_data.paso,
  proces_spl_data_filtro: (state) => state.formInputs.proces_spl_data.filtro,
  proces_spl_data_orden: (state) => state.formInputs.proces_spl_data.orden,
  proces_d11: (state) => state.formInputs.proces_d11,
  proces_d11_data: (state) => state.formInputs.proces_d11_data,
  proces_d11_data_filtro: (state) => state.formInputs.proces_d11_data.filtro,
  proces_d11_data_orden: (state) => state.formInputs.proces_d11_data.orden,
  equipo_list: (state) => state.formInputs.equipo_list,
  equipo_selected: (state) => state.formInputs.equipo_selected,
  sound_crop_start: (state) => state.formInputs.sound_crop_start,
  sound_crop_end: (state) => state.formInputs.sound_crop_end,
  
  
  signal_props: (state) => state.signalProps,
  signal_nums: (state) => state.signalProps.signal_nums,
  job_id: (state) => state.stateVars.job_id,
  num_files_uploaded: (state) => state.stateVars.num_files_uploaded,
  fileNames: (state) => state.stateVars.fileNames,

  filtro_list: (state) => state.filtro_list,
  paso_list: (state) => state.paso_list,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  VueToast,
  Vuelidate,
};
