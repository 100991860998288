import * as localData from "@/services/localData.js";
import Vue from "vue";
import { getData, postData, deleteData } from "@/services/api";
import { toaster_success, toaster_error } from "../../services/helper";
import { getlocalToken } from "../../services/localData";
var jwt = require("jsonwebtoken");
// const jose = require('jose')

const state = () => ({
  status: "",
  auth_token: localData.getlocalToken("auth_token") || "",
  refresh_token: localData.getlocalToken("refresh_token") || "",
  username: localData.getlocalToken("username") || "",
  user_id: localData.getlocalToken("user_id") || "",
  is_admin: localData.getlocalToken("is_admin") || "",
  first_name: localData.getlocalToken("first_name") || "",
  last_name: localData.getlocalToken("last_name") || "",
  email: localData.getlocalToken("email") || "",
  city: localData.getlocalToken("city") || "",
  country: localData.getlocalToken("country") || "",
  interest: localData.getlocalToken("interest") || [""],
  experience: localData.getlocalToken("experience") || "",
  linkedin: localData.getlocalToken("linkedin") || "",
  token_exp: localData.getlocalToken("token_exp") || "",
  // privacy: localData.getlocalToken('privacy') || '',

  viewComponent: "login",

  favs: localData.getlocalToken("favs") || [],
});

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },

  auth_success(state, data) {
    state.status = "success";
    state.auth_token = data.auth_token;
    state.refresh_token = data.refresh_token;
    state.username = data.username;
    state.user_id = data.user_id;
    state.is_admin = data.is_admin;
    state.first_name = data.first_name;
    state.last_name = data.last_name;
    state.email = data.email;
    state.city = data.city;
    state.country = data.country;
    state.interest = data.interest;
    state.experience = data.experience;
    state.linkedin = data.linkedin;
    state.favs = data.favs; //esto?
  },

  auth_error(state) {
    state.status = "error";
  },

  logout(state) {
    state.status = "";
    state.auth_token = "";
    state.refresh_token = "";
    state.username = "";
    state.user_id = "";
    state.is_admin = "";
    state.first_name = "";
    state.last_name = "";
    state.email = "";
    state.city = "";
    state.country = "";
    state.interest = [""];
    state.experience = "";
    state.linkedin = "";
    state.favs = []; //esto?
  },

  auth_validate(state, auth_token) {
    state.status = "success";
    state.auth_token = auth_token;
    state.token_exp = true;
  },

  validate_token(state, data) {
    state.token_exp = data;
  },

  to_login(state) {
    state.viewComponent = "login";
  },

  to_register(state) {
    state.viewComponent = "register";
  },

  to_recover_pass(state) {
    state.viewComponent = "forgot";
  },

  to_reset_pass(state) {
    state.viewComponent = "reset";
  },

  to_add_fav(state, job) {
    state.favs.push(job);
  },

  to_update_favs(state, jobs) {
    state.favs = jobs;
  },
};

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      let data = {};
      postData("auth-jwt/login/", user)
        .then((resp) => {
          if (resp.data.access) {
            getData("auth-jwt/getFavs/?user_id=" + resp.data.id).then(
              (favs) => {
                if (favs.data) {
                  //revisar esto!
                  data = {
                    auth_token: resp.data.access,
                    refresh_token: resp.data.refresh,
                    username: resp.data.username,
                    user_id: resp.data.id,
                    is_admin: resp.data.is_admin,
                    first_name: resp.data.first_name,
                    last_name: resp.data.last_name,
                    email: resp.data.email,
                    city: resp.data.city,
                    country: resp.data.country,
                    interest: resp.data.interest,
                    experience: resp.data.experience,
                    linkedin: resp.data.linkedin,
                    favs: favs.data, //revisar esto!!
                  };

                  localData.setlocalToken("auth_token", data.auth_token);
                  localData.setlocal("refresh_token", data.refresh_token);
                  localData.setlocal("username", data.username);
                  localData.setlocal("user_id", data.user_id);
                  localData.setlocal("is_admin", data.is_admin);
                  localData.setlocal("first_name", data.first_name);
                  localData.setlocal("last_name", data.last_name);
                  localData.setlocal("email", data.email);
                  localData.setlocal("city", data.city);
                  localData.setlocal("country", data.country);
                  localData.setlocal("interest", data.interest);
                  localData.setlocal("experience", data.experience);
                  localData.setlocal("linkedin", data.linkedin);
                  localData.setlocal("favs", data.favs); //esto?

                  commit("auth_success", data);
                  toaster_success(
                    "Welcome " + data.first_name + " " + data.last_name
                  );
                  resolve(resp);
                }
              }
            );
          } else {
            commit("auth_error");
            localData.deleteAll();
            //Vue.noty.error('Incorrect password or user') //Cuidado, esto a veces es por error de red.
            toaster_error("Incorrect password or user"); //Cuidado, esto a veces es por error de red.
          }
        })
        .catch((error) => {
          commit("auth_error");
          toaster_error("Error updating user: Unknown Error");
          localData.deleteAll();
          reject(error);
        });
    });
  },

  refresh_token({ commit }) {
    if (this.state.user.refresh_token) {
      postData("auth-jwt/refresh/", { refresh: this.state.user.refresh_token })
        .then((res) => {
          if (res.status == 200) {
            localData.setlocalToken("auth_token", res.data.access);
            commit("auth_validate", res.data.access);
          } else if (res.status == 401) {
            localData.deleteAll();
            commit("logout");
            location.reload();
            // toaster_error("Expired Sesion. Please login");
          } else {
            localData.deleteAll();
            commit("logout");
            // toaster_error("Something went wrong. Get in touch with Administrator");
            location.reload();
          }
        })
        .catch((err) => {
          // toaster_error("Something went wrong. Get in touch with Administrator");
          localData.deleteAll();
          location.reload();
        });
    }
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("logout");
      localData.deleteAll();
      toaster_success("See you soon ;)");
      Vue.router.push("/");
      resolve("OK");
    });
  },

  password_reset({ commit }, email) {
    return new Promise((resolve, reject) => {
      postData("auth-jwt/password-reset/", { email: email })
        .then((res) => {
          if (res.status == 200 && res.data.status == "OK") {
            toaster_success(
              "An email has been sent to account " +
                email +
                " to help you reset the password. Please read it and follow the instructions."
            );
            resolve("ok");
          } else if (res == "Error: Network Error") {
            toaster_error(
              "Something went wrong. Please contact your administrator."
            );
            reject("error");
          } else {
            toaster_error("Please, make sure that your email is correct");
            reject("error");
          }
        })
        .catch((error) => {
          toaster_error("Something went wrong", error);
          reject(error);
        });
    });
  },

  confirmResetPass({ commit }, form) {
    return new Promise((resolve, reject) => {
      postData("auth-jwt/password-reset/confirm/", form)
        .then((res) => {
          if (res.status == 200 && res.data.status == "OK") {
            toaster_success("Password updated successfully");
            resolve("ok");
          } else if (res.status == 404) {
            toaster_error(
              "Password could not be reset. This link is no longer available."
            );
            reject(res.statusText);
          } else {
            toaster_error(
              "Something went wrong. Please contact your administrator."
            );
            reject("Something went wrong. Please contact your administrator");
          }
        })
        .catch((error) => {
          toaster_error(
            "Something went wrong. Please contact your administrator. " + error
          );
          reject(error);
        });
    });
  },

  to_login({ commit }) {
    commit("to_login");
  },

  to_register({ commit }) {
    commit("to_register");
  },

  to_recover_pass({ commit }) {
    commit("to_recover_pass");
  },

  to_reset_pass({ commit }) {
    commit("to_reset_pass");
  },

  //add or remove fav
  to_add_remove_fav({ commit }, user_job) {
    return new Promise((resolve, reject) => {
      let favs = getlocalToken("favs");

      //if fav is favorite --> remove fav
      if (favs.find((element) => element == user_job.job) == user_job.job) {
        //remove from database
        getData(
          "auth-jwt/getFavId/?user_id=" +
            user_job.user +
            "&job_id=" +
            user_job.job
        ) 
          .then((res) => {
            const favsId = res.data;
            favsId.forEach((element) => {
              deleteData("jobs/favs/", element).then(() => {
                //update favs list in localstorage and store
                getData("auth-jwt/getFavs/?user_id=" + user_job.user).then(
                  (favs) => {
                    if (favs.data) {
                      commit("to_update_favs", favs.data);
                      localData.setlocal("favs", favs.data);
                      resolve("ok");
                    }
                  }
                );
              });
              //.catch(console.log("error deleting fav")); //revisar esto...
            });
          });
      }
      //else --> add fav
      else {
        //update favs in database
        postData("jobs/favs/", user_job)
          .then((res) => {
            if (res.status == 201) {
              // update favs in store
              commit("to_add_fav", user_job.job);
              //update favs in localstorage
              favs = getlocalToken("favs"); //igual esto no hace falta
              favs.push(user_job.job);
              localData.setlocal("favs", favs);
              // toaster_success("Job added to favs");
              resolve("ok");
            } else if (res.status == 404) {
              toaster_error("Error 404.");
              reject(res.statusText);
            } else {
              toaster_error(
                "Something went wrong. Please contact your administrator."
              );
              reject("Something went wrong. Please contact your administrator");
            }
          })
          .catch((error) => {
            toaster_error(
              "Something went wrong. Please contact your administrator. " +
                error
            );
            reject(error);
          });
      }
    });
  },


};

const getters = {
  isLoggedIn: (state) => !!state.auth_token,
  authStatus: (state) => state.status,
  token: (state) => state.auth_token,
  username: (state) => state.username,
  user_id: (state) => state.user_id,
  is_admin: (state) => state.is_admin,
  first_name: (state) => state.first_name,
  last_name: (state) => state.last_name,
  email: (state) => state.email,
  city: (state) => state.city,
  country: (state) => state.country,
  interest: (state) => state.interest,
  experience: (state) => state.experience,
  linkedin: (state) => state.linkedin,

  viewComponent: (state) => state.viewComponent,
  getFavs: (state) => state.favs,
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
