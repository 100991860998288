// import Vue from 'vue'
import { getData, postData } from "@/services/api";
import { toaster_error, parse_filters_data } from '../../services/helper';

var countries = require("i18n-iso-countries");
// Support english languages.
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));


const state = () => ({
    jobs: '', 
    // {
    //     creation_date: '',
    //     user: '',
    
    //     job_title: '',
    //     link: '',
    //     deadline: '',

    //     organisation: '',
    //     type_of_organisation: '',

    //     sector: '',
    //     career_level: '',

    //     country: '',
    //     city: '',
    // }

    jobsCount: 0,
    actualPage: '1',
    nextPage: null,
    previousPage: null,

    //De momento no los uso. Serían necesarios si quiero recuperarlos al cargar la home
    // filters: {
    //   search_jobs: "Data",
    //   country: "ES",
    //   sector: "",
    //   type_of_organisation: "",
    //   career_level: "",
    //   deadline: "",
    // },


    
})


const mutations = {
  // set_job_title(state, data){
  //   state.job_title = data
  // },

  set_jobs(state, data) {
      data.forEach(job => {
        // Parse deadline to Date or Permanent
        var epochDate = Date.parse(job.deadline)
        if (!isNaN(epochDate)){
          var d = new Date(0);
          d.setUTCMilliseconds(epochDate);
          job.closed = false;
          if (d < Date.now()){job.closed = true;} 
          job.deadlineConverted = d.getDate() + "/" + (d.getMonth()+1) + "/" +d.getFullYear();
        } else {
          job.deadlineConverted = 'Permanent'
        }
        // Parse country code to country name
        job.countryName = countries.getName(job.country, "en", {select: "official"})
      })

      state.jobs = data
  },

  set_pagination(state, data) {
    state.jobsCount = data.count
    state.nextPage = data.next
    state.previousPage = data.previous
  },

  set_page(state, data) {
    state.actualPage = data
  },

}

const actions = {   

    fetch_jobs({commit, dispatch}, data) {           
            dispatch("ui/change_status", 'loading', { root: true });
            //commit('parse_filters_data', filters_data)
            getData('jobs/jobs/?' + parse_filters_data(data.filters_data) + 'page='+ data.actualPage)
            //getData("jobs/jobs/?job_title__contains='Data'&page=1")
            .then(res=> {
                if(res.status == 200) {        
                    commit('set_jobs', res.data.results)
                    commit('set_pagination', res.data)
                    dispatch("ui/change_status", 'ok', { root: true });
                } else if (res.status != undefined) {
                    toaster_error("Error fetching Jobs: " + res.statusText)
                    dispatch("ui/change_status", 'error', { root: true });
                } else {
                    toaster_error("Error fetching Jobs: Network Error")
                    dispatch("ui/change_status", 'error', { root: true });
                }
            })
            .catch(err => {
                toaster_error("Error fetching Jobs: Unknown Error")
                dispatch("ui/change_status", 'error', { root: true });
            })
    },

    fetch_last_jobs({commit, dispatch}) {
      dispatch("ui/change_status", 'loading', { root: true });
      getData('jobs/last-jobs/')
      .then(res=> {
          if(res.status == 200) {  
              commit('set_jobs', res.data.last_jobs)
              dispatch("ui/change_status", 'ok', { root: true });
          } else if (res.status != undefined) {
              toaster_error("Error fetching Jobs: " + res.statusText)
              dispatch("ui/change_status", 'error', { root: true });
          } else {
              toaster_error("Error fetching Jobs: Network Error")
              dispatch("ui/change_status", 'error', { root: true });
          }
      })
      .catch(err => {
          toaster_error("Error fetching Jobs: Unknown Error")
          dispatch("ui/change_status", 'error', { root: true });
      })
},

    set_page({ commit }, data) {
        commit("set_page", data);
    },


    // update_filters({commit}, filters) {
    //   commit("update_filters", filters)
    // }


}

 const getters = {
    
    // job_title: (state) => state.job_title,
    jobs: (state) => state.jobs,
    jobsCount: (state) => state.jobsCount,
    actualPage: (state) => state.actualPage,
    nextPage: (state) => state.nextPage,
    previousPage: (state) => state.previousPage,
    // filters: (state) => state.filters,

  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }