<template>
    <div class="container pt-4">
        <nav class="row justify-content-md-center">
            <ul class="pagination">
                
                <li class="page-item">
                    <a class="page-link" @click="toPreviousPage" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only"  >Previous</span>
                    </a>
                </li>

                <li class="page-item" v-for='index in totalPages' :key='index' @click="toSelectPage(index)">
                    <a class="page-link" href="#"> {{ index }}</a>
                </li>
    
                <li class="page-item">
                    <a class="page-link" @click="toNextPage" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>


    </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
    name: "Pagination",
    components: {},
    
    data() {
        return {
            totalPages: 1    
        };
    },

    watch: {
        // whenever question changes, this function will run
        jobsCount: function () {
            this.totalPages = Math.ceil(this.jobsCount/10)       
        }
    },

    computed: { 
        ...mapGetters("jobs",{
            jobsCount: 'jobsCount',
            actualPage: 'actualPage',
            nextPage: 'nextPage',
            previousPage: 'previousPage', 
        })      
    },

    mounted() {
        this.totalPages = Math.ceil(this.jobsCount/10)
    },

    methods: {
        toNextPage: function () {
            var page = '1';
            if (this.nextPage !== null){
                page = this.nextPage.slice(this.nextPage.indexOf('?page=')+6);
                this.$store.dispatch("jobs/set_page", page)      
            }
        },

        toPreviousPage: function () {
            var page = '1';
            if (this.previousPage !== null){
                if (this.previousPage.indexOf('?') == -1){
                    page = 1;
                } else {
                    page = this.previousPage.slice(this.previousPage.indexOf('?page=')+6);
                }
                this.$store.dispatch("jobs/set_page", page)
            }
        },

        toSelectPage: function (page) {
            this.$store.dispatch("jobs/set_page", page)  
        },
    },
};
</script>

<style lang = "scss" scoped>

</style>