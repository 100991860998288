
//It's important import bootstrap before VUE. Thus, configuration in Sass overwrite default Bootstrap classes (like body{} for example).
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css' 

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as localData from '@/services/localData.js'
// import VueNoty from 'vuejs-noty'
// import 'vuejs-noty/dist/vuejs-noty.css'
import Vuelidate from 'vuelidate'
import VueHtml2Canvas from 'vue-html2canvas'
import vueCountryRegionSelect from 'vue-country-region-select'
import "./registerServiceWorker"


Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(Vuelidate)
// Vue.use(VueNoty)
Vue.use(VueHtml2Canvas)
Vue.use(vueCountryRegionSelect)

// console.log(vueCountryRegionSelect.CountrySelect.computed.countries())
// console.log(vueCountryRegionSelect)

const auth_token = localData.getlocalToken('auth_token')

if (auth_token) {
  localData.setlocalToken('auth_token',auth_token)
}

new Vue({
  beforeCreate () {
    Vue.prototype.$http = axios
    //axios.defaults.headers.common['Authorization'] = "Bearer  " + localData.getlocalToken('auth_token')
    //axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    axios.defaults.xsrfCookieName = 'csrftoken' 
  },
  router,
  store,
  localData,
  render: h => h(App)
}).$mount('#app')

