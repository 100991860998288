<template>
  <div class="container-fluid p-0 home-bg">
   
    
    <div class="h-100 job_background row ">
  

      <div class="row col-lg-12  justify-content-md-center job_content">
    
          <pulse-loader class="ml-loader"  v-if="status=='loading'"  :color="spinerColor"></pulse-loader>
          <div v-else>
             <!-- Componente Job -->
            <job></job>

            <!-- Componente para Paginación -->
          </div>

      </div> <!-- max width -->
    
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
// import {postData} from '@/services/api'
import dsptHeader from '@/components/header';
import job from '@/components/jobs/job';
import pagination from '@/components/jobs/pagination';
import { mapGetters } from "vuex";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: "LastJobs",
  created() {},
  components: {
    dsptHeader,
    job,
    pagination,
    PulseLoader
  },
  
  methods: {},

  data() {
    return {
      spinerColor: '#32AC91',
    };
  },

  watch: {
    // whenever question changes, this function will run
    // actualPage: function () {
    //   this.$store.dispatch("jobs/fetch_jobs", this.actualPage)
    // }
  },

  computed: {
    //  ...mapGetters({actualPage: 'jobs/actualPage', status: 'ui/status' }) 
     ...mapGetters({status: 'ui/status' }) 
  },
  
  mounted() {
    // this.$store.dispatch("jobs/fetch_jobs", this.actualPage)
    // this.$store.dispatch("jobs/set_last_jobs", 3) //Show only 3 jobs
    this.$store.dispatch("jobs/fetch_last_jobs")
  },
};
</script>

<style lang = "scss" scoped>
</style>

