<template>
    <div class="modal fade  bd-example-modal-lg" id="userProfile" tabindex="-1" role="dialog" aria-labelledby="userProfileLabel" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content p-4">
                <userEdit  v-bind:user = user  :personalProfile = "true"></userEdit>
            <div class="row p-2">
                <div class="col-sm-12 text-right">
                    <a type="button" class="link-1" data-dismiss="modal">Close</a>
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

import userEdit from '@/components/user/userEdit.vue'
export default {
  name: 'userProfile',
  components: {
    userEdit
  },
  data() {
    return {
        user: {
            id:'',
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            city: '',
            country: '',
            interest: [""],
            experience: '',
            linkedin: '',
        }
    };
  },
  mounted () {
      this.user['id'] = this.user_id
      this.user['username'] = this.username
      this.user['first_name'] = this.first_name
      this.user['last_name'] = this.last_name
      this.user['email'] = this.email
      this.user['city'] = this.city
      this.user['country'] = this.country
      this.user['interest'] = this.interest
      this.user['experience'] = this.experience
      this.user['linkedin'] = this.linkedin
  },
  created () {

  },
  methods: {

  }
};
</script>

<style lang = "scss" scoped>
.modal-dialog {
  max-width: 850px;
}
</style>
