import Vue from 'vue'

const toaster_success = (msg) => {
    return Vue.$toast.open({
        message: msg,
        type: "success",
        position: "top",
        pauseOnHover: true,
        dismissible: true,
        duration: 5000, 
      });
}

const toaster_error = (msg) => {
    return Vue.$toast.open({
        message: msg,
        type: "error",
        position: "top",
        pauseOnHover: true,
        dismissible: true,
        duration: 5000, 
      });
}


const parse_filters_data = (filters_data) => {
 
    let filters_parsered = '';
    if (filters_data.search_jobs !== "") { //OK
        filters_parsered = "job_title__contains='" + filters_data.search_jobs + "'&";
    }

    if (filters_data.country !== "") { //OK
            filters_parsered += "country='" + filters_data.country + "'&";
    }

    if(filters_data.vlocation !== false) {
        filters_parsered += "various_locations=True&"
    }

    if (filters_data.sector.length !== 0) { //OK
            filters_parsered += "sector__icontains='" + filters_data.sector + "'&";
    }

    if (filters_data.type_of_organisation.length !== 0) { //OK
        filters_parsered += "type_of_organisation__icontains='" + filters_data.type_of_organisation + "'&";
    }

    if (filters_data.career_level.length !== 0) { //OK
            filters_parsered += "career_level__icontains='" + filters_data.career_level + "'&";
    }

    if (filters_data.deadline !== "") {
        filters_parsered += "deadline='" + filters_data.deadline + "'&";
    }

    return filters_parsered;
  }

export {
    toaster_success,
    toaster_error,
    parse_filters_data,
}