<template>
  <div class="container-fluid p-0 home-bg">
    <dsptHeader></dsptHeader>

    <div class="h-100 job_background row">
      <!-- Job Header -->
      <div class="row col-lg-12 justify-content-md-center job_header pt-5 pb-5">
        <!-- Job Header Title -->
        <div class="row col-lg-9 justify-content-md-center job_header_title">
          <div>
            <h1 class="job_title text-center">blue-jobs</h1>
            <h3 class="job_subtitle text-center">
              The online platform to find a job in marine sciences
            </h3>
          </div>
        </div>

        <!-- Job Header Search -->
        <form
          class="row col-lg-6 justify-content-md-center job_header_search mt-5"
          @submit.prevent="updateHeaderFilters"
        >
          <div
            class="row col-lg-12 justify-content-md-center job_header_search"
          >
            <input
              class="col-lg-7 job_inputs_search"
              v-model="filterHeader.search_jobs"
              type="search_jobs"
              placeholder="Search jobs"
            />

            <country-select
              class="col-lg-3 job_inputs_search"
              v-model="filterHeader.country"
              :country="filterHeader.country"
            />

            <!-- 
            <select
              class="col-lg-3 job_inputs_search"
              id="experience"
              type="experience"
              v-model="filterForm.country"
            >
              <option value="" selected disabled>Country:</option>
              <option>Intern & recent graduate</option>
              <option>Junior (1-5 years of experience)</option>
              <option>Senior (<5 years of experience)</option>
            </select> -->

            <div class="col-lg-2 job_inputs_search p-2 pl-3">
              <button
                type="submit"
                class="btn btn-secondary job_button_search p-2"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- Job Content Full -->
      <div class="row col-lg-12 justify-content-md-center job_content pt-5">
        <!-- Job Content -->
        <div class="row col-lg-7 p-0">
          <!-- Filtros -->
          <div class="col col-lg-4 p-0 pr-5">
            <div>Refine Search:</div>
            <!-- Sectors -->
            <!-- <select id="inputState" class="form-control job_filter_select mt-4" v-model="filters.sector" > -->
            <select
              id="inputState"
              class="custom-select job_filter_select mt-4"
              v-model="filterForm.sector"
              multiple
            >
              <option value="" selected>Sector</option>
              <!-- añadir disabled si se quiere desabilitar la opción que da nombre al título. -->
              <option
                v-for="(sector, index) in sectors"
                v-bind:key="index"
                :value="index"
              >
                {{ sector }}
              </option>
            </select>

            <!-- Type of organisation -->

            <select
              id="inputState"
              class="custom-select job_filter_select mt-4"
              v-model="filterForm.type_of_organisation"
              multiple
            >
              <option value="" selected>Type of organisation</option>
              <!-- añadir disabled si se quiere desabilitar la opción que da nombre al título. -->
              <option
                v-for="(type_of_organisation, index) in type_of_organisations"
                v-bind:key="index"
                :value="index"
              >
                {{ type_of_organisation }}
              </option>
            </select>

            <!-- Career level -->
            <select
              id="inputState"
              class="custom-select job_filter_select mt-4"
              v-model="filterForm.career_level"
              multiple
            >
              <option value="" selected>Career level</option>
              <!-- añadir disabled si se quiere desabilitar la opción que da nombre al título. -->
              <option
                v-for="(career_level, index) in career_levels"
                v-bind:key="index"
                :value="index"
              >
                {{ career_level }}
              </option>
            </select>

            <!-- Deadline -->
            <select
              id="inputState"
              class="custom-select job_filter_select mt-4"
              v-model="filterForm.deadline"
              multiple
            >
              <option value="" selected>Deadline</option>
              <!-- añadir disabled si se quiere desabilitar la opción que da nombre al título. -->
              <option>Open</option>
              <option>Closed</option>
              <option>Permanent</option>
            </select>

            <!-- Country -->
            <country-select
              class="custom-select job_filter_select mt-4"
              v-model="filterForm.country"
              :country="filterForm.country"
            />
            Various Locations
            <input
              type="checkbox"
              v-model="vlocation"
              data-toggle="toggle"
              data-size="sm"
            />

            <!-- <select
              id="inputState"
              class="custom-select job_filter_select mt-4"
              v-model="filterForm.country"
            >
              <option value="" selected disabled>Country</option>
              <option>xxx</option>
              <option>yyyy</option>
              <option>Xxxx</option>
            </select> -->

            <!-- Clear filters -->
            <div class="text-center">
              <button
                v-on:click="clearFilter"
                class="btn btn-secondary job_button_clear_filters mt-4"
              >
                clear all filters
              </button>
            </div>
          </div>

          <!-- Jobs -->
          <div class="col col-lg-8 text-center my-auto">
            <pulse-loader
              class="ml-loader"
              v-if="status == 'loading'"
              :color="spinerColor"
            ></pulse-loader>
            <div v-else>
              <div v-if="Object.keys(jobs).length !== 0">
                <!-- Componente Job -->
                <job></job>
                <!-- Componente para Paginación -->
                <pagination></pagination>
              </div>
              <div v-else>
                Ups! Not matches found, please try to use different filters in
                your search.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- max width -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import {postData} from '@/services/api'
import dsptHeader from "@/components/header";
import job from "@/components/jobs/job";
import pagination from "@/components/jobs/pagination";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  sectors,
  type_of_organisations,
  career_levels,
} from "../services/consts";
import Datepicker from "vuejs-datepicker";

export default {
  name: "Jobs",
  created() {},
  components: {
    dsptHeader,
    job,
    pagination,
    PulseLoader,
    Datepicker,
  },

  data() {
    return {
      country: "",
      spinerColor: "#32AC91",
      sectors: sectors,
      type_of_organisations: type_of_organisations,
      career_levels: career_levels,
      filterHeader: {
        search_jobs: "",
        country: "",
      },
      filterForm: {
        country: "",
        sector: [],
        type_of_organisation: [],
        career_level: [],
        deadline: [],
      },
      vlocation: false,
    };
  },

  methods: {},

  watch: {
    // whenever question changes, this function will run
    actualPage: function (newValue) { //revisar! igual se puede llamar a updatefilters del tirón.
      // let filters_data;
      // if (this.filterHeader.country === "") {
      //   filters_data = { ...this.filterHeader, ...this.filterForm, 'vlocation': this.vlocation };
      // } else {
      //   filters_data = { ...this.filterForm, ...this.filterHeader, 'vlocation': this.vlocation };
      // }
      let filters_data;

      if (this.filterForm.country !== this.filterHeader.country ) { 
        this.vlocation = false;
        this.filterHeader.country = this.filterForm.country;
      } 
      filters_data = { ...this.filterHeader, ...this.filterForm, 'vlocation': this.vlocation};
  
      let actualPage = newValue;
      this.$store.dispatch("jobs/fetch_jobs", { filters_data, actualPage });

    },

    filterForm: {
      deep: true,
      handler: function () {
        this.updateFilters();
      },
    },

    vlocation: function (newValue) {
      if(newValue === true) {
        this.filterHeader.country = "";
        if (this.filterForm.country !== "") {
          this.filterForm.country = "";
        } else {
          this.updateFilters();
        }
      } else {
        this.updateFilters();
      }

    }
  },

  computed: {
    ...mapGetters({
      actualPage: "jobs/actualPage",
      jobs: "jobs/jobs",
      status: "ui/status",
    }),
  },

  mounted() {
    let filters_data;
    if (this.filterHeader.country === "") {
      filters_data = { ...this.filterHeader, ...this.filterForm,  'vlocation': this.vlocation };
    } else {
      filters_data = { ...this.filterForm, ...this.filterHeader,  'vlocation': this.vlocation};
    }
    let actualPage = this.actualPage;
    this.$store.dispatch("jobs/fetch_jobs", { filters_data, actualPage });
  },

  methods: {
    updateHeaderFilters: function () {
      if (this.filterForm.country !== this.filterHeader.country) {
          if(this.vlocation === true) {
            this.vlocation = false;
          }
          this.filterForm.country = this.filterHeader.country;
        } else {
          if(this.vlocation === true) {
            this.vlocation = false;
          } else {
            this.updateFilters();
          }
        }
    },

    updateFilters: function () {
      this.$store.dispatch("jobs/set_page", 1);
      let filters_data;

      if (this.filterForm.country !== this.filterHeader.country ) { 
        this.vlocation = false;
        this.filterHeader.country = this.filterForm.country;
      } 
      filters_data = { ...this.filterHeader, ...this.filterForm, 'vlocation': this.vlocation};
  
      let actualPage = this.actualPage;
      this.$store.dispatch("jobs/fetch_jobs", { filters_data, actualPage });
    },

    clearFilter: function () {
      this.$store.dispatch("jobs/set_page", 1);
      this.filterHeader = {
        search_jobs: "",
        country: "",
      };

      this.filterForm = {
        country: "",
        sector: [],
        type_of_organisation: [],
        career_level: [],
        deadline: [],
      };

      this.vlocation = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
