<template>
  <form class="form-login" @submit.prevent="login">
    <div class="form-group row">
      <div class="col-12 text-center">
        <label for="Login info">Use your credentials to access</label>
      </div>
      <!-- input-group.// -->
    </div>
    <div class="form-group row pb-1">
      <div class="col-12 pl-0">
        <img
          class="icons-login"
          :src="require('@/assets/icons/icon-user.svg')"
          alt="user icon"
        />
        <input
          class="form-generic"
          required
          v-model="email"
          type="email"
          placeholder="Email"
        />
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row pb-2">
      <div class="col-12 pl-0">
        <img
          class="icons-login"
          :src="require('@/assets/icons/icon-password.svg')"
          alt="password icon"
        />
        <input
          class="form-generic"
          required
          v-model="password"
          type="password"
          placeholder="Password"
        />
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row">
      <div class="col-12 mx-auto fit-btn-position text-center">
        <button type="submit" class="btn btn-outline-secondary">LOGIN</button>
      </div>
      <a
        class="text-center pt-2 link-1 w-100"
        v-on:click="to_recover_pass"
        >I forgot my password</a
      >
      <!-- <a  class="text-center  pt-3 link-1 w-100" >Registro de nuevo usuario</a> -->
    </div>
    
    <div class="form-group row pb-2">
      <div class="col pl-0 text-center row">
        <div class="col-9">Don't have an account?</div>
        <!-- <a href="#" class="col-3 badge ">Register!</a> -->
        <router-link   to="/login" exact> 
            <a @click="to_register" class="modal-call pointer">Register!</a>
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
  },
  methods: {
    login: function () {
      var userdata = {
        email: this.email,
        password: this.password,
      };
      this.$store
        .dispatch("user/login", userdata)
        .then(() => this.$router.push("/"))
        .catch((err) => console.log(err));
    },

    to_register: function () {
      this.$store.dispatch("user/to_register");
    },

    to_recover_pass: function () {
      this.$store.dispatch("user/to_recover_pass");
    }
  },
};
</script>

<style lang = "scss" scoped>
.form-login {
  margin-top: 25px;
}
</style>
