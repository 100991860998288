<template>
  <form @submit.prevent="reset">
    <div class="form-group row">
      <div class="col-md-12">
        <label class="pb-2">Type a new password</label>
        <input
          class="form-control form-password"
          required
          v-model="password"
          :v="$v.password"
          type="password"
          placeholder="Password"
        />
        <div v-if="$v.password.$error ">
            <label v-if="!$v.password.minLength" class=" col-form-label invalid p-0">Password must be at least 8 characters long</label>
            <label v-if="!$v.password.notOnlyNumeric" class=" col-form-label invalid p-0">Password cannot have only numbers</label>
        </div>
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row pb-3">
      <div class="col-md-12">
        <input
          class="form-control form-password"
          required
          v-model="password2"
          type="password"
          :v="$v.password2"
          placeholder="Repeat password"
        />
        <label v-if="$v.password2.$error" class=" col-form-label invalid p-0"
          >Passwords do not match
        </label>
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row">
      <div class="col-md-8 mx-auto text-center">
        <pulse-loader class="ml-loader"  v-if="this.loading"  :color="spinerColor"></pulse-loader>
        <button type="submit" class="btn btn-outline-secondary">
          Send
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import {
  required,
  minLength,
  sameAsPassword,
  sameAs,
  not,
  numeric
} from "vuelidate/lib/validators";

export default {
  name: "reset-pass",
  components: {
    PulseLoader
  },
  props: {
    token: String
  },
  data() {
    return {
      password: "",
      password2: "",
      loading: false,
      spinerColor: '#32AC91',
    };
  },
  mounted() {
  },
  validations: {
    password: {
      required,
      notOnlyNumeric:not(numeric),
      minLength: minLength(8),
    },
    password2: {
      required, 
      sameAsPassword: sameAs("password") 
      }
  },
  methods: {
    reset: function() {
      
      this.$v.password.$touch();
      this.$v.password2.$touch();
      if (this.$v.password.$error || this.$v.password2.$error) return;

      this.loading = true
      var form = {
        password: this.password,
        token: this.token
      };
      
      this.$store.dispatch("user/confirmResetPass", form)
      .then(res => {
        this.password = '';
        this.password2 = '';
        this.loading = false
        if(res=='ok') {
          // this.$parent.viewComponent = 'login'; 
          this.to_login();
        } 
      })
      .catch(error => {
        console.log('Error: ', error)
        this.loading = false
        })
    },

    to_login: function () {
      this.$store.dispatch("user/to_login");
    }
  }
};
</script>

<style lang= "scss" scoped>

  $border-color: #32AC91;

</style>
