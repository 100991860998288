import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import ui from './modules/ui.js'
import memberAdmin from "./modules/memberAdmin.js";
import jobs from './modules/jobs.js'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    ui,
    memberAdmin,
    jobs,
  },
  strict: debug,
  // plugins: debug? [ createLogger() ] : [],
  state: {},
  mutations: {},
  actions: {},
});
