const state = () => ({
  status: 'ok', // 1.- ok / 2.- loading / 3.- Waiting for response / 4.- error
  
})

const mutations = {
  set_status(state, data){
    state.status = data
  },

}

const actions = {
  change_status({ commit }, data) {
    commit("set_status", data)
  },

}

 const getters = {
    status: state => state.status,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }