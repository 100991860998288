<template>
  <div id="app">
    <loader></loader>
    <!-- <dsptHeader></dsptHeader> -->
      <router-view></router-view>
    <!-- <dsptFooter></dsptFooter> -->
  </div>
</template>


<script>
import dsptHeader from '@/components/header'
import dsptFooter from '@/components/footer'
import loader from '@/components/loader'


export default {
  name: 'App',
  beforeMount(){
    //this.$store.dispatch("user/validate_token"); //TESTEANDO
    this.$store.dispatch('user/refresh_token')
  },
  components: {
    dsptFooter,
    dsptHeader,
    loader,
    // confirmModal,
    // notificationModal
  },

   data() {
    return {}
  },

  computed: { 
  },

  mounted () {

  },

  methods: { 
  }
}
</script>

<style lang="scss">
#app {
  // width:100%; 
  // // height:100%;
  // min-height: 500px; 
  // // position:fixed; 
  // left:0;
  // top:0;
  // overflow:hidden;
}

</style>
