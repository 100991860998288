<template>
  <footer>
         © 2021 Process App by Pablo Ruiz
    </footer>
</template>

<script>
export default {
  name: 'dsptFooter',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

  footer{

    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $footer-default;
    color: $footer-text-default;
    padding: 0.2rem 0.2rem 0.2rem 0.5rem;
    margin-top: 20px;
  }
</style>
