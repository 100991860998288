<template>
  <div class="container-fluid p-0 home-bg">
    <dsptHeader></dsptHeader>
    <div class="row h-100">
      <div class="col-lg-12  text-center  my-auto">Admin </br> Row 1</div>
      <div class="col-lg-12  text-center  my-auto">Admin </br> Row 2</div>
      
      </div>
  </div>
</template>

<script>
// Leaflet
import dsptHeader from "@/components/header";

// Vuex
import { mapGetters } from "vuex";

// Components and services

export default {
  name: "Admin",
  components: {
    dsptHeader,
  },
  computed: {
    ...mapGetters({
      status: "ui/status",
      current_interface: "ui/current_interface",
    }),
  },
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {
 
  },
  watch: {

  },

  methods: {
   
  },
};
</script>
<style scoped>
</style>