import Vue from 'vue'
import VueRouter from 'vue-router'
import Jobs from '../views/Jobs.vue'
import LastJobs from '../views/LastJobs.vue'
import Admin from '../views/Admin.vue'
import MemberAdmin from '../views/MemberAdmin.vue'
import User from '../views/User.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Jobs",
    component: Jobs,
    meta: {
      requiresAuth: true, //deberá ser true
    }
  },
    {
    path: "/last-jobs",
    name: "LastJobs",
    component: LastJobs,
    meta: {
      requiresAuth: false, 
    }
  },
  {
    path: "/login",
    name: "Login",
    component: User,
    meta: {
      requiresNotAuth: true,
    },
  },
  {
    path: "/member-admin",
    name: "MemberAdmin",
    component: MemberAdmin,
    meta: {
      requiresAuth: true //deberá ser true
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true //deberá ser true
    }
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresNotAuth)) {
    if (!store.getters['user/isLoggedIn']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['user/isLoggedIn']) {
      next()
      return
    } 
    //store.dispatch("user/logout"); //TESTEANDO
    next('login')
    
  } else {
    next()
  }
})


export default router
