<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        
        <router-link  class="navbar-title" to="/" exact>
        <img :src="require('@/assets/logo.png')" style="max-height: 32px"/>
            User
        </router-link>

            
        <!-- Right side of Navbar -->
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="nav navbar-nav navbar-right ml-auto">
            
            <li class="nav-item dropdown mr-5 ">
              
              <a v-if="isLoggedIn" id="navbarDropdown" class=" dropdown-toggle link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <p class="d-inline-block nav-user m-0 link-1">{{first_name}} {{last_name}}</p>
              </a>

              <!-- User options pop up -->
              <div class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
                <div class="popup-body">
                  <div v-if="is_admin == true || is_admin == 'true'" class="dropdown-item modal-call pointer" data-toggle="modal" data-target="#management"> Users Management</div>
                  <div v-if="is_admin == true || is_admin == 'true'" class="line-separator"><hr /></div>
                  <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#userProfile" > &nbsp;User profile</div>
                  <div class="line-separator"><hr /></div>
                  <router-link   to="/member-admin" exact> 
                    <a class="dropdown-item modal-call pointer">Admin</a>
                  </router-link>  
                  <div v-if="is_admin == true || is_admin == 'true'" class="line-separator"><hr /></div>
                  <div>
                    <a  @click="logout" class="dropdown-item pointer" href="">
                      &nbsp;Logout
                    </a>
                  </div>
                </div>
              </div>

            </li>

          </ul>
        </div>

      </div>
    </nav>
    <management v-if="is_admin == true || is_admin == 'true'"></management> <!--La gestión de usuarios deberá ir dentro del componente MemberAdmin-->
    <userProfile v-if="isLoggedIn == true || isLoggedIn == 'true'"></userProfile>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import management from '@/components/user/management.vue'
import userProfile from '@/components/user/userProfile.vue'

export default {
  name: "dsptHeader",
  components: {
    management,
    userProfile
  },
  data() {
    return {
      // userName: first_name + '' + last_name
    };
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      is_admin: 'is_admin',
      first_name: 'first_name',
      last_name: 'last_name'
    })
 
  },
  methods:{
    logout: function () {
      this.$store.dispatch('user/logout')
      .then(res => res)
      .catch(err => err)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>


.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 10000;
}



</style>
