<template>
  <form @submit.prevent="sendEmail">
    <div class="form-group row pt-4">
      <div class="col-12 text-center">
        <label for="Recuperar contraseña">Type the mail address linked with your user account. You will receive an email with further instructions to reset your password.</label>
      </div>
    </div>
    <div class="form-group row pb-1">
      <div class="col-12 pl-0">
        <img class ='icons-login'
          :src="require('@/assets/icons/icon-user.svg')"
          alt="email icon"
        />
        <input
          class="form-generic"
          required
          v-model="email"
          type="email"
          placeholder="Email address"
        />
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row ">
      
      <div class="col-12 mx-auto fit-btn-position text-center">
        <pulse-loader class="ml-loader"  v-if="this.loading"  :color="spinerColor"></pulse-loader>
        <button v-else type="submit" class="btn btn-outline-secondary">
          SEND EMAIL
        </button>
      </div>
        <a  class="ml-auto mr-auto mt-5 pt-2 link-1" v-on:click="to_login" >Back to login screen</a>
    </div>
  </form>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'forgotten-pass',
  components: {
    PulseLoader
  },
  data() {
    return {
      email: "",
      loading: false,
      spinerColor: '#32AC91',
    };
  },
  mounted () {
  },
  methods: {
    sendEmail: function () {
        this.loading = true
        this.$store.dispatch("user/password_reset", this.email)
        .then(res => {
          this.loading = false
          if(res=='ok') {
            this.email = ' '
          }
        })
        .catch(error => {
          this.loading = false
        })
    },

    to_login: function () {
      this.$store.dispatch("user/to_login");
    }
  }
};
</script>

<style lang = "scss"  scoped>
.ml-loader {
  /* padding-left: 38px; */
}

</style>
