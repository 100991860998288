const sectors = {
	CC: "Climate change",
	CEW: "Conservation, ecology & wildlife",
	EE: "Environmental education",
	FA: "Fisheries & aquaculture",
    MC: "Management & consultancy",
	MP: "Marine policy",
	MRE: "Marine renewable energy",
	NME: "Naval & marine engineering",
    OC: "Oceanography",
	WH: "Water & hydrology",
}

const type_of_organisations = {
	CO: "Consultancy",
	AD: "Administration",
	AN: "Association & NGO",
	IO: "International organisation",
	PS: "Private sector",
	RTC: "Research & technology centre",
	UA: "University & academia",
}

const career_levels = {
	IN: "Intern & recent graduate",
	JU: "Junior (1-5 years of experience)",
	SE: "Senior (>5 years of experience)",
}

const deadlines = {
	OPEN: "Open",
	CLOSED: "Closed",
	PERMANENT: "Permanent",

}


export {
    sectors,
	type_of_organisations,
	career_levels,	
}

